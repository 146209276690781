import axios from 'axios'
import { supabase } from '@/supabase'
import store from '@/stores'

const httpClient = axios.create({
    baseURL: import.meta.env.VITE_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

httpClient.interceptors.request.use(async (request) => {
    const access_token = (await supabase.auth.getSession())?.data?.session?.access_token
    if (access_token) {
        request.headers.Authorization = `Bearer ${access_token}`
        request.headers['Content-Type'] = 'application/json'
    }

    if (store.state.creatorReferral) {
        request.headers['X-Referrer'] = store.state.creatorReferral || ''
    }

    if (store.state.ip) {
        request.headers['X-Forwarded-For'] = store.state.ip + (request.headers['X-Forwarded-For'] ? `, ${request.headers['X-Forwarded-For']}` : '')
    }
    return request
})

httpClient.interceptors.response.use(
    response => response,
    (error) => {
        if (!error.response) {
            return {
                status: 500,
            }
        }
        if (error.response.status >= 400 && error.response.status < 500) {
            // Handle errors
            console.log(error.response)
        }
        else if (error.response.status >= 500) {
            console.log(error.response)
        }
        return error.response
    },
)

export default httpClient
