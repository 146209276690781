import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from './authGuard'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Landing.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Home',
            },
            strict: true,
        },
        {
            path: '/early-access',
            name: 'early-access',
            component: () => import('@/views/Demo.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Early Access',
            },
            strict: true,
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import('@/views/Signup.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Signup',
            },
            strict: true,
        },
        {
            path: '/dashboard/:section?/:option?',
            name: 'dashboard',
            component: () => import('@/views/Dashboard.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Dashboard',
            },
            strict: true,
        },
        {
            path: '/r/:roster_link?',
            name: 'shared_roster',
            component: () => import('@/views/SharedRoster.vue'),
            props: true,
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Shared Roster',
            },
            strict: true,
        },
        {
            path: '/verify',
            name: 'verify',
            component: () => import('@/views/Verify.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Verify',
            },
            strict: true,
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('@/views/ResetPassword.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Reset Password',
            },
            strict: true,
        },
        {
            path: '/logout',
            name: 'logout',
            component: () => import('@/views/LogOut.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Logout',
            },
            strict: true,
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: () => import('@/views/PrivacyPolicy.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Privacy',
            },
            strict: true,
        },
        {
            path: '/connect',
            name: 'connect',
            component: () => import('@/views/CreatorOnboarding.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Creator Onboarding',
            },
            strict: true,
        },
        {
            path: '/content',
            name: 'content',
            component: () => import('@/views/ContentReview.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Content',
            },
            strict: true,
        },
        {
            path: '/join',
            name: 'join',
            component: () => import('@/views/Join.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Join',
            },
            strict: true,
        },
        {
            path: '/c/:creator_link',
            name: 'creator',
            component: () => import('@/views/CreatorLink.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Creator',
            },
            strict: true,
        },
        // REMEMBER: When adding any new routes, be sure to include them in vercel.json as well.
    ],
})

router.beforeEach(authGuard)

router.onError((err) => {
    console.log('err', err)
    router.push('/404')
})

export default router
