import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

export function authGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> | void {
    const authStore = useAuthStore()
    if (to.path === '/login' && authStore.isAuthenticated) {
        return next({ name: 'home' })
    }

    if (!to.meta.authRequired || authStore.isAuthenticated) {
        return next()
    }
    else {
        authStore.saveRedirectRoute(to)
        return next({ name: 'login' })
    }
}
