import AppLayout from '@/layouts/AppLayoutApp.vue'
import { all } from '@awesome.me/kit-6c68669ced/icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Logtail } from '@logtail/browser'
import { DocumentEditorComponent } from '@syncfusion/ej2-vue-documenteditor'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueVirtualScroller from 'vue-virtual-scroller'
import Vue3Marquee from 'vue3-marquee'
import App from './App.vue'
import router from './router'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import './assets/main.css'
import './assets/tailwind.css'
import '@vime/core/themes/default.css'

library.add(...all)

const app = createApp(App)

let logtail: Logtail | null = null

if (import.meta.env.VITE_APP_LOGTAIL_TOKEN) {
    logtail = new Logtail(import.meta.env.VITE_APP_LOGTAIL_TOKEN)
}

app.use(createPinia())
app.use(router)
app.use(Vue3Marquee)
app.use(VueVirtualScroller)

app.config.errorHandler = function (err: any, vm, info) {
    console.error('Global error:', err)
    console.log('Vue instance:', vm)
    console.log('Error info:', info)
    if (logtail) {
        logtail.error(err)
        logtail.log(info)
        logtail.flush()
    }
    return null
}

app.component('font-awesome-icon', FontAwesomeIcon).component('AppLayout', AppLayout).component('ejs-documenteditor', DocumentEditorComponent).mount('#app')

// export { logtail }
