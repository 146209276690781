<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import store from '../stores/index'

export default defineComponent({
    name: 'AppLayoutApp',
    setup() {
        onMounted(() => store.init())
    },
})
</script>

<template>
    <main class="absolute inset-0">
        <slot />
    </main>
</template>
