<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { supabase } from '@/supabase'
import { registerLicense } from '@syncfusion/ej2-base'
import LogRocket from 'logrocket'
import { RouterView, useRouter } from 'vue-router'
import type { RouteLocationRaw } from 'vue-router'

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NCaF1cXGJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXdceHRcQmJdWEN1XEY=')

const router = useRouter()
const authStore = useAuthStore()

if (import.meta.env.VITE_APP_ENV === 'production') {
    LogRocket.init('mdp7er/lighthouse')
}

supabase.auth.onAuthStateChange((event) => {
    if (event === 'SIGNED_IN') {
        authStore.loadUser()
        authStore.loadRedirectRoute()
        if (import.meta.env.VITE_APP_ENV === 'production') {
            LogRocket.identify(authStore.currentUser.id, {
                name: `${authStore.currentUser.user_metadata.first_name} ${authStore.currentUser.user_metadata.last_name}`,
                email: authStore.currentUser.email,
                userType: authStore.currentUser.user_metadata.type,
            })
        }
    }
    else if (event === 'SIGNED_OUT') {
        authStore.clearUser()
    }
})

authStore.$onAction(({ name, store, after }) => {
    if (name === 'loadRedirectRoute') {
        after(async () => {
            const redirectRoute = store.redirectRoute

            if (redirectRoute) {
                await router.isReady()
                await router.replace(redirectRoute as RouteLocationRaw)
                authStore.clearRedirectRoute()
            }
        })
    }
})
</script>

<template>
    <AppLayout>
        <RouterView />
    </AppLayout>
</template>

<style scoped>
header {
    line-height: 1.5;
    max-height: 100vh;
}

.logo {
    display: block;
    margin: 0 auto 2rem;
}

nav {
    width: 100%;
    font-size: 12px;
    text-align: center;
    margin-top: 2rem;
}

nav a.router-link-exact-active {
    color: var(--color-text);
}

nav a.router-link-exact-active:hover {
    background-color: transparent;
}

nav a {
    display: inline-block;
    padding: 0 1rem;
    border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
    border: 0;
}

@media (min-width: 1024px) {
    header {
        display: flex;
        place-items: center;
        padding-right: calc(var(--section-gap) / 2);
    }

    .logo {
        margin: 0 2rem 0 0;
    }

    header .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
    }

    nav {
        text-align: left;
        margin-left: -1rem;
        font-size: 1rem;

        padding: 1rem 0;
        margin-top: 1rem;
    }
}
</style>
